@font-face {
  font-family: 'MrsMonster';
  src: url('./mrsmonster.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MonsterBoo';
  src: url('./monster-boo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  overflow: auto; /* Allow scrolling */
}

.App {
  text-align: center;
  background-color: black;
  color: #F2a900;
  width: 100vw; /* Ensure full width */
  overflow-x: hidden;
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh; /* Full height for the first section */
}

.App-header h1 {
  font-size: 9.5vw; /* Reduce size by 5% */
  font-weight: bold;
  font-family: 'MrsMonster', sans-serif;
  color: #f7931a;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Slight white glow */
  margin-bottom: 0; /* Remove bottom margin */
  margin-top: 0; /* Remove top margin */
}

.dripping-text {
  position: relative;
  display: inline-block;
  z-index: 1;
  letter-spacing: 5%; /* Add spacing between the letters */
}

.dripping-text span {
  position: relative;
  display: inline-block;
}

.dripping-text span::after {
  content: '';
  position: absolute;
  top: 50%; /* Start from the middle of the letter */
  left: 50%;
  width: 5px;
  height: 5px;
  background: #8B0000; /* Blood red color */
  border-radius: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  opacity: 0; /* Hide initially */
  animation: drip var(--random-duration) infinite linear;
  animation-delay: var(--random-delay);
}

@keyframes drip {
  0% {
    transform: translateY(0) translate(-50%, -50%);
    opacity: 1;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  50% {
    transform: translateY(50px) translate(-50%, -50%);
    width: 3px;
    height: 20px;
    border-radius: 50% 50% 50% 50% / 70% 70% 30% 30%;
  }
  100% {
    transform: translateY(100px) translate(-50%, -50%);
    opacity: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
}

.free-mint {
  display: flex;
  align-items: center;
  font-size: 3.8vw; /* Reduce size by 5% */
  color: #f7931a;
  margin: 0; /* Remove all margins */
  padding-top: 10px; /* Adjust padding to control spacing */
  font-family: 'MrsMonster', sans-serif; /* Apply MrsMonster font */
  font-style: italic; /* Make the text italic */
}

.free-mint .line {
  flex-grow: 1;
  height: 2px;
  background-color: #f7931a;
  margin: 0 10px;
  width: 8rem; /* Adjust to match the width of the word "MONSTERS" */
}

.icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px; /* Add space between icons */
}

.icon {
  width: 40px; /* Maintain original size */
  height: 40px; /* Maintain original size */
}

/* Background logos */
.background-logo {
  position: absolute;
  width: 50px;
  height: 50px;
  opacity: 0.15; /* Reduce opacity by 25% from the original 0.2 */
  pointer-events: none; /* Prevent interaction */
}

/* New section for description */
.description {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: black;
  color: #f7931a;
  padding: 20px;
  width: 100%;
  height: 100vh; /* Full height for the second section */
  box-sizing: border-box;
  position: relative; /* To contain background logos */
}

.description-text {
  width: 45%;
  font-family: 'MonsterBoo', sans-serif;
  text-align: left;
  margin-right: 2%; /* Add a bit of space between text and image */
}

.description-text h2 {
  font-size: 5.7vw; /* Reduce size by 5% */
  margin: 0;
}

.description-text p {
  font-size: 2.85vw; /* Reduce size by 5% */
  margin: 10px 0;
}

.description-image {
  width: 20%; /* Original size */
  display: flex;
  justify-content: center;
}

.description-image img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Add this line to make the edges curved */
}

/* New background logos for description section */
.background-logo-joystick {
  position: absolute;
  width: 50px;
  height: 50px;
  opacity: 0.15;
  pointer-events: none;
  fill: #f7931a; /* Set the color to match the text */
}

.wallet-checker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh; /* Set height to 75% of the screen */
  background-color: #000000; /* Changed background color to black */
  padding: 10px;
}

.wallet-checker-title {
  font-size: 54px; /* Adjust the size as needed */
  color: #f7931a; /* Changed text color to #f7931a */
  margin-bottom: 30px; /* Increased spacing */
  text-align: center;
  font-family: 'MonsterBoo', sans-serif; /* Added font-family */
}

.wallet-checker-input-container {
  display: flex;
  align-items: center;
}

.wallet-checker-bitcoin-input {
  width: 300px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.wallet-checker-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  background-color: #f7931a;
  cursor: pointer;
  box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.5); /* Reduced white glow by 60% */
}

.wallet-checker-icon-button img {
  width: 20px;
  height: 20px;
}

.wallet-checker-paste-icon {
  width: 20px; 
  height: 20px; 
}

.wallet-checker-icon-button:hover {
  background-color: #cc7514;
}

.wallet-checker-message {
  margin-top: 20px;
  font-size: 32px; /* Adjusted for better readability */
  text-align: center;
  font-family: 'MonsterBoo', sans-serif; /* Added font-family */
}

.wallet-checker-message.green {
  color: green;
}

.wallet-checker-message.orange {
  color: orange;
}

.wallet-checker-message.red {
  color: red;
}

@media (max-width: 600px) {
  .wallet-checker-input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .wallet-checker-bitcoin-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .wallet-checker-icon-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .wallet-checker-message {
    font-size: 24px; /* Adjusted for smaller screens */
  }
}

.carousel-section {
  background-color: black;
  color: #f7931a;
  padding: 20px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.carousel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add space between carousels */
}

.carousel-container {
  width: 200%; /* Adjust to fit all images for a seamless loop */
  display: flex;
  overflow: hidden;
}

.carousel-item {
  flex: 0 0 auto;
  width: calc(11.1% - 10px); /* Show 9 items at a time with some space */
  margin: 0 5px; /* Add some space between items */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.top-carousel {
  animation: scrollRightToLeft 36s linear infinite;
}

.bottom-carousel {
  animation: scrollLeftToRight 36s linear infinite;
}

@keyframes scrollRightToLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%); /* Adjust to loop all 18 items (9 images x 2) */
  }
}

@keyframes scrollLeftToRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%); /* Adjust to loop all 18 items (9 images x 2) */
  }
}

/* Scroll to top button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  opacity: 0.7; /* Slightly see-through */
  cursor: pointer;
  z-index: 1000; /* Ensure the button is above other elements */
}

.scroll-to-top.scrolled {
  fill: #f7931a; /* Change color */
  stroke: white; /* Add white outline */
}

.scroll-to-top:hover {
  opacity: 1; /* Fully opaque on hover */
}

/* Responsive Design */
@media (min-width: 769px) {
  .description-text {
    margin-right: 5%; /* Increase the margin for larger screens */
  }
}

@media (max-width: 1100px) {
  .description-text h2 {
    font-size: 6vw; /* Increase the font size for better readability */
  }

  .description-text p {
    font-size: 4vw; /* Increase the font size for better readability */
  }
}

@media (max-width: 768px) {
  .App-header h1 {
    font-size: 14.25vw; /* Reduce size by 5% */
  }

  .free-mint {
    font-size: 5.7vw; /* Reduce size by 5% */
  }

  .icon {
    width: 35px; /* Adjust icon size for tablets */
    height: 35px;
  }

  .description {
    flex-direction: column; /* Stack the text and image vertically */
    align-items: center; /* Center content horizontally */
  }

  .description-text {
    width: 80%; /* Adjust width for smaller screens */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add space between text and image */
    text-align: center; /* Center text */
  }

  .description-image {
    width: 50%; /* Adjust image size for smaller screens */
    display: flex;
    justify-content: center;
  }

  .wallet-checker-input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .wallet-checker-bitcoin-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .wallet-checker-icon-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .wallet-checker-message {
    font-size: 24px; /* Adjusted for smaller screens */
  }

  .carousel-item {
    width: calc(14.28% - 10px); /* Increase item width for larger images */
  }

  .carousel-container {
    flex-direction: row; /* Ensure horizontal layout */
  }

  .carousel-section {
    height: 100vh; /* Maintain height for smaller screens */
  }
}

@media (max-width: 480px) {
  .App-header h1 {
    font-size: 19vw; /* Reduce size by 5% */
  }

  .free-mint {
    font-size: 7.6vw; /* Reduce size by 5% */
  }

  .icon {
    width: 30px; /* Adjust icon size for mobile devices */
    height: 30px;
  }

  .description-text h2 {
    font-size: 10vw; /* Increase size for better readability */
  }

  .description-text p {
    font-size: 6vw; /* Increase size for better readability */
  }

  .description-text {
    width: 90%; /* Adjust width for mobile devices */
  }

  .description-image {
    width: 50%; /* Adjust image size for mobile devices */
    display: flex;
    justify-content: center;
  }

  .description {
    flex-direction: column;
    text-align: center;
  }

  .description-text {
    width: 100%;
    margin-right: 0; /* Remove margin for mobile devices */
  }

  .wallet-checker-input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .wallet-checker-bitcoin-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .wallet-checker-icon-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .carousel-item {
    width: calc(20% - 10px); /* Increase item width for larger images */
  }

  .carousel-container {
    flex-direction: row; /* Ensure horizontal layout */
  }

  .carousel-section {
    height: 100vh; /* Maintain height for smaller screens */
  }
}
